import { ProviderType } from "./ObjectTypes";

export const v1_technologies: ProviderType[] = [
  {
    name: "African Wildlife Tracking (Animal Tracking)",
    id: "",
    value: "awts",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/awts",
    description: "",
    actions: []
  },
  {
    name: "Argos (Animal Tracking)",
    id: "",
    value: "argos",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/argos",
    description: "",
    actions: []
  },
  {
    name: "Ashored (Rope-On-Demand)",
    id: "",
    value: "ashored",
    help_center_url: "https://support.earthranger.com/en_US/manufacturers/ashored",
    description: "",
    actions: []
  },
  {
    name: "Baytrac (Vehicle Tracker)",
    id: "",
    value: "baytrac",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/baytrac",
    description: "",
    actions: []
  },
  {
    name: "Bidtrack (Vehicle Tracker)",
    id: "",
    value: "bidtrack",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/bidtrack",
    description: "",
    actions: []
  },
  {
    name: "Blue Ocean Gear (Rope-On-Demand)",
    id: "",
    value: "blue-ocean-gear",
    help_center_url: "https://support.earthranger.com/en_US/manufacturers/blue-ocean-gear",
    description: "",
    actions: []
  },
  {
    name: "CarTrack (Vehicle Tracker)",
    id: "",
    value: "cartrack",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/cartrack",
    description: "",
    actions: []
  },
  {
    name: "Ceres Wild (Animal Tracking)",
    id: "",
    value: "ceres-wild",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/ceres-wild",
    description: "",
    actions: []
  },
  {
    name: "Connected Conservation Foundation (IoT)",
    id: "",
    value: "connected-conservation-foundation",
    help_center_url: "https://support.earthranger.com/en_US/iot/connected-conservation-foundation",
    description: "",
    actions: []
  },
  {
    name: "cTrack (Vehicle Tracker)",
    id: "",
    value: "ctrack",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/ctrack",
    description: "",
    actions: []
  },
  {
    name: "DevOcean (Rope-On-Demand)",
    id: "",
    value: "devocean",
    help_center_url: "https://support.earthranger.com/en_US/manufacturers/devocean",
    description: "",
    actions: []
  },
  {
    name: "DigitalMatter (IoT)",
    id: "",
    value: "digital-matter",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/digital-matter",
    description: "",
    actions: []
  },
  {
    name: "DigitAnimal (Animal Tracking)",
    id: "",
    value: "digitanimal",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/digitanimal",
    description: "",
    actions: []
  },
  {
    name: "Druid Technology (Animal Tracking)",
    id: "",
    value: "druid-technology",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/druid-technology",
    description: "",
    actions: []
  },
  {
    name: "EarthRanger to EarthRanger (Conservation Technology)",
    id: "",
    value: "er2er",
    help_center_url: "https://support.earthranger.com/en_US/earthranger_conservation_software/er2er",
    description: "",
    actions: []
  },
  {
    name: "EdgeTech (Rope-On-Demand)",
    id: "",
    value: "edgetech",
    help_center_url: "https://support.earthranger.com/en_US/manufacturers/edgetech",
    description: "",
    actions: []
  },
  {
    name: "Everywhere Hub (Radios)",
    id: "",
    value: "everywhere-communications",
    help_center_url: "https://support.earthranger.com/en_US/radios/everywhere-communications",
    description: "",
    actions: []
  },
  {
    name: "EzyTrack (Vehicle Tracker)",
    id: "",
    value: "ezytrack",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/ezytrack",
    description: "",
    actions: []
  },
  {
    name: "Fargo Tracking, Wells Fargo (Vehicle Tracker)",
    id: "",
    value: "wells-fargo-tracking",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/wells-fargo-tracking",
    description: "",
    actions: []
  },
  {
    name: "FindMy (Animal Tracking)",
    id: "",
    value: "findmy",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/findmy",
    description: "",
    actions: []
  },
  {
    name: "Flow (Animal Tracking)",
    id: "",
    value: "flow-tech-solutions",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/flow-tech-solutions",
    description: "",
    actions: []
  },
  {
    name: "Followit (Animal Tracking)",
    id: "",
    value: "followit",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/followit",
    description: "",
    actions: []
  },
  {
    name: "Garmin InReach (Radios)",
    id: "",
    value: "garmin-inreach",
    help_center_url: "https://support.earthranger.com/en_US/radios/garmin-inreach",
    description: "",
    actions: []
  },
  {
    name: "Gestfrota (Vehicle Tracker)",
    id: "",
    value: "gesfrota",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/gesfrota",
    description: "",
    actions: []
  },
  {
    name: "GSatSolar (Animal Tracking)",
    id: "",
    value: "gsatsolar",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/gsatsolar",
    description: "",
    actions: []
  },
  {
    name: "ICUServer (Camera Trap)",
    id: "",
    value: "icuserver",
    help_center_url: "https://support.earthranger.com/en_US/camera-trap/icuserver",
    description: "",
    actions: []
  },
  {
    name: "iTrack (Vehicle Tracker)",
    id: "",
    value: "itrack",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/itrack",
    description: "",
    actions: []
  },
  {
    name: "iTrackLive (Vehicle Tracker)",
    id: "",
    value: "itracklive",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/itracklive",
    description: "",
    actions: []
  },
  {
    name: "Kerlink Gateway (IoT)",
    id: "",
    value: "kerlink",
    help_center_url: "https://support.earthranger.com/en_US/iot/kerlink",
    description: "",
    actions: []
  },
  {
    name: "Liquid Tech (IoT)",
    id: "",
    value: "liquid-intelligence-technologies",
    help_center_url: "https://support.earthranger.com/en_US/iot/liquid-intelligence-technologies",
    description: "",
    actions: []
  },
  {
    name: "Lotek (Animal Tracking)",
    id: "",
    value: "lotek",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/lotek",
    description: "",
    actions: []
  },
  {
    name: "Mapipedia (Data Platform)",
    id: "",
    value: "mapipedia",
    help_center_url: "https://support.earthranger.com/en_US/data-platforms/mapipedia",
    description: "",
    actions: []
  },
  {
    name: "Mireo (Vehicle Tracker)",
    id: "",
    value: "mireo",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/mireo",
    description: "",
    actions: []
  },
  {
    name: "Movebank (Conservation Technology)",
    id: "",
    value: "receiving-data-from-movebank",
    help_center_url: "https://support.earthranger.com/en_US/movebank/receiving-data-from-movebank",
    description: "",
    actions: []
  },
  {
    name: "NamTrack (Vehicle Tracker)",
    id: "",
    value: "namtrack",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/namtrack",
    description: "",
    actions: []
  },
  {
    name: "NAVSAT (Vehicle Tracker)",
    id: "",
    value: "navsat",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/navsat",
    description: "",
    actions: []
  },
  {
    name: "OroraTech (Remote Imagery)",
    id: "",
    value: "ororatech",
    help_center_url: "https://support.earthranger.com/en_US/remote-imagery/ororatech",
    description: "",
    actions: []
  },
  {
    name: "Owley (Animal Tracking)",
    id: "",
    value: "owley",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/owley",
    description: "",
    actions: []
  },
  {
    name: "Rock7 (Radios)",
    id: "",
    value: "rockseven",
    help_center_url: "https://support.earthranger.com/en_US/radios/rockseven",
    description: "",
    actions: []
  },
  {
    name: "Ropeless Buoy (Rope-On-Demand)",
    id: "",
    value: "rope-on-demand",
    help_center_url: "https://support.earthranger.com/rope-on-demand",
    description: "",
    actions: []
  },
  {
    name: "Savannah Tracking (Animal Tracking)",
    id: "",
    value: "savannah-tracking",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/savannah-tracking",
    description: "",
    actions: []
  },
  {
    name: "Sentinel (Camera Trap)",
    id: "",
    value: "sentinel",
    help_center_url: "https://support.earthranger.com/en_US/camera-trap/sentinel",
    description: "",
    actions: []
  },
  {
    name: "Sigfox (IoT)",
    id: "",
    value: "sigfox",
    help_center_url: "https://support.earthranger.com/en_US/iot/sigfox",
    description: "",
    actions: []
  },
  {
    name: "SMART to EarthRanger (Conservation Technology)",
    id: "",
    value: "receiving-data-from-smart-connect",
    help_center_url: "https://support.earthranger.com/en_US/smart/receiving-data-from-smart-connect",
    description: "",
    actions: []
  },
  {
    name: "SMELTS (Rope-On-Demand)",
    id: "",
    value: "smelts",
    help_center_url: "https://support.earthranger.com/en_US/manufacturers/smelts",
    description: "",
    actions: []
  },
  {
    name: "SpoorTrack (Vehicle Tracker)",
    id: "",
    value: "spoortrack",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/spoortrack",
    description: "",
    actions: []
  },
  {
    name: "Telonics (Animal Tracking)",
    id: "",
    value: "telonics",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/telonics",
    description: "",
    actions: []
  },
  {
    name: "Traccar (Vehicle Tracker)",
    id: "",
    value: "traccar",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/traccar",
    description: "",
    actions: []
  },
  {
    name: "Track Solid (Vehicle Tracker)",
    id: "",
    value: "track-solid",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/track-solid",
    description: "",
    actions: []
  },
  {
    name: "TrackIt (Vehicle Tracker)",
    id: "",
    value: "trackit",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/trackit",
    description: "",
    actions: []
  },
  {
    name: "Ultimate Tracking (Vehicle Tracker)",
    id: "",
    value: "ultimate-tracking",
    help_center_url: "https://support.earthranger.com/en_US/vehicle-trackers/ultimate-tracking",
    description: "",
    actions: []
  },
  {
    name: "JVA",
    id: "",
    value: "jva",
    help_center_url: "https://support.earthranger.com/en_US/iot/jva",
    description: "",
    actions: []
  },
  {
    name: "Wildlife Computers (Animal Tracking)",
    id: "",
    value: "wildlife-computers",
    help_center_url: "https://support.earthranger.com/en_US/animal-tracking/wildlife-computers",
    description: "",
    actions: []
  }
];