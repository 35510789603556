import React from 'react'
import Select, { components } from 'react-select'
import { NavBarSortedDown, NavBarSortedUp } from './ProjectIcons';
import { languageSelectStyle, timezoneStyle } from './componentstyles/selectinputstyles';

type SelectInputProps = {
    options: Array<any>,
    className: string,
    defaultValue?: object,
    value?: object,
    isDisabled: boolean,
    isLoading: boolean,
    isClearable: boolean,
    isSearchable: boolean,
    isMulti: boolean,
    name: string,
    onChange: Function,
    onMenuScrollToBottom?: any,
    handleInputChange?: any,
    languageSelect?: boolean,
    timezoneSelect?: boolean
}

const SelectInput = ({
    options,
    className,
    defaultValue,
    value,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    isMulti,
    name,
    onChange,
    onMenuScrollToBottom,
    handleInputChange,
    languageSelect,
    timezoneSelect
}: SelectInputProps) => {
    const DropdownIndicator = (props: any) => {
        const { selectProps } = props;
        return (
            <components.DropdownIndicator {...props}>
                {selectProps.menuIsOpen ? <NavBarSortedUp /> : <NavBarSortedDown />}
            </components.DropdownIndicator>
        );
    };
    const styles = timezoneSelect ? timezoneStyle : languageSelect ? languageSelectStyle : {};
    return (
        <Select
            options={options}
            className={className}
            defaultValue={defaultValue}
            value={value}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isMulti={isMulti}
            name={name}
            onChange={(e) => onChange(e)}
            onMenuScrollToBottom={onMenuScrollToBottom}
            onInputChange={handleInputChange}
            styles={styles}
            components={(languageSelect || timezoneSelect) === true ? { DropdownIndicator } : {}}
        />
    )
};

export default SelectInput;