import { getConnectionProviderTypes } from "./connectionsApi";
import { ProviderType } from "../../components/common/ObjectTypes";
import { page_size } from "../apiUtils";
import { v1_technologies } from "../../components/common/gundiV1Technologies";

export const loadProviderTypes = async (
    keycloak_token: string,
    page_offset: number,
    providerTypesSearchInput: string,
    providerTypes: ProviderType[],
    append: boolean = false
): Promise<[ProviderType[], number, Error | null]> => {
    let total_provider_types = 0;

    try {
        const query = {
            offset: page_offset,
            search: providerTypesSearchInput,
            search_fields: "name,value",
            ordering: "name",
            previous:
                process.env.REACT_APP_BASE_URL_LOCAL +
                "/integrations/urls/?is_provider=true&pager=limit&limit=" +
                page_size +
                "&offset=" +
                (page_offset - page_size),
            next:
                process.env.REACT_APP_BASE_URL_LOCAL +
                "/integrations/urls/?is_provider=true&pager=limit&limit=" +
                page_size +
                "&offset=" +
                (page_offset + page_size),
        };

        // Fetch API provider types
        const temp = await getConnectionProviderTypes(keycloak_token, query);
        let apiResults: ProviderType[] = temp?.results ?? [];
        total_provider_types = temp?.count ?? 0;

        // Case 1: No API results and no search input
        if (apiResults.length === 0 && providerTypesSearchInput.length === 0) {
            return [[], 0, null];  // Return empty array, do not append anything
        }

        // Remove duplicates from API results
        const uniqueApiResults = Array.from(new Set(apiResults.map((obj) => obj.id))).map(
            (id) => apiResults.find((obj) => obj.id === id)!
        );

        // Get first & last API result names for alphabetical filtering
        const firstApiName = uniqueApiResults.length > 0 ? uniqueApiResults[0]?.name?.toLowerCase() ?? "" : "";
        const lastApiName = uniqueApiResults.length > 0 ? uniqueApiResults[uniqueApiResults.length - 1]?.name?.toLowerCase() ?? "" : "";

        // Search filtering for v1 technologies
        const filteredV1Results = v1_technologies.filter((provider) => {
            const providerName = provider.name?.toLowerCase() ?? "";
            const providerValue = provider.value?.toLowerCase() ?? "";
            const searchQuery = providerTypesSearchInput.toLowerCase();
            const nameMatches = providerName.includes(searchQuery);
            const valueMatches = providerValue.includes(searchQuery);

            const isWithinAlphabeticalRange =
                uniqueApiResults.length === 0 || (providerName > firstApiName && providerName < lastApiName);

            return (nameMatches || valueMatches) && isWithinAlphabeticalRange;
        });

        // If no API results and search input exists, return only filtered v1 results
        if (uniqueApiResults.length === 0 && providerTypesSearchInput.length > 0) {
            const filteredV1Results = v1_technologies
                .filter(provider =>
                    provider.name.toLowerCase().includes(providerTypesSearchInput.toLowerCase()) ||
                    provider.value.toLowerCase().includes(providerTypesSearchInput.toLowerCase())
                )
                .sort((a, b) => a.name.localeCompare(b.name));

            return [filteredV1Results, filteredV1Results.length, null];
        }

        // Combine API and filtered V1 results, then sort alphabetically
        const combinedResults = [...uniqueApiResults, ...filteredV1Results].sort((a, b) =>
            a.name.localeCompare(b.name)
        );

        // Append results if needed
        const finalResults = append ? [...providerTypes, ...combinedResults] : combinedResults;

        return [finalResults, total_provider_types + filteredV1Results.length, null];
    } catch (err: any) {
        console.error(err);
        return [[], 0, err];
    }
};