import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import logo_grey from "../../images/logo_grey.png";
import { useKeycloak } from "@react-keycloak/web";
import { NavLink } from "react-router-dom";
import { HelpIcon, ProfileDropdown, NavBarSortedUp, NavBarSortedDown } from "./ProjectIcons";
import type { KeycloakTokenParsed } from 'keycloak-js';
import { EulaModal } from "./EulaModal";
import { getEula } from "../../api/users/usersApi";
import SelectInput from "./SelectInput";
import { portalLanguages } from "./ObjectTypes";
import { inferCurrentLanguage } from "../multilanguage/utils";
import { getUtcOffsets } from "../activityLogs/ActivityLogsHelperFunctions";

type ParsedToken = KeycloakTokenParsed & {
  email?: string
  preferred_username?: string
  given_name?: string
  family_name?: string
}

const Nav = () => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const divRef = useRef<HTMLDivElement | null>(null);
  const isLoggedIn = localStorage.getItem("userAuthorized") !== null
  const [timezones, setTimezones] = useState<any[]>([]);
  const [selectedTimezone, setSelectedTimezone] = useState<any>({ label: "UTC", value: "+0000" });
  const [subMenu, setSubMenu] = useState(false);
  const [helpMenu, setHelpMenu] = useState(false);
  const [showEULAModal, setShowEULAModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(inferCurrentLanguage(i18n.language, t));
  const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
  const toggleSubMenu = () => setSubMenu(!subMenu);
  const toggleHelpMenu = () => setHelpMenu(!helpMenu);
  const loginUser = () => {
    keycloak.login();
  }

  const getEulaStatus = async () => {
    if (keycloak.token) {
      const results = await getEula(keycloak.token);
      if (!results.accepted) {
        setShowEULAModal(true);
      }
    }
  }

  const logoutUser = () => {
    keycloak.logout()
    localStorage.removeItem("userAuthorized")
  }

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang).then(() => {
      setCurrentLanguage(inferCurrentLanguage(i18n.language, t))
    });
  };

  const getBrowserTimezoneOffset = (offsets: any) => {
    const now = new Date();
    const offset = -now.getTimezoneOffset();
    const hours = Math.floor(offset / 60);
    const minutes = offset % 60;
    const sign = hours >= 0 ? "+" : "-";
    const formattedOffset = `${sign}${String(Math.abs(hours)).padStart(2, '0')}${String(Math.abs(minutes)).padStart(2, '0')}`;

    const valueLabelObject = offsets.find((o: any) => o.value === formattedOffset);
    if (valueLabelObject) {
      localStorage.setItem("selectedTimezone", JSON.stringify(valueLabelObject));
    }
    return valueLabelObject.value;
  };

  useEffect(() => {
    keycloak.onAuthSuccess = () => {
      if (!keycloak.isTokenExpired(0)) {
        localStorage.setItem("userAuthorized", "true");
        keycloak.loadUserProfile();
        getEulaStatus();
      }
    };

    function handleClickOutside(event: any) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // Clicked outside of the div, so close it.
        setSubMenu(false);
        setHelpMenu(false);
      }
    }

    let temp = getUtcOffsets();
    setTimezones(temp);

    const offsets = getUtcOffsets();
    const browserOffset = getBrowserTimezoneOffset(offsets);
    const defaultTimezone = temp.find(tz => tz.value === browserOffset) || { label: "UTC", value: "+0000" };
    setSelectedTimezone(defaultTimezone);

    // Attach the event listener when the component mounts.
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts.
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectTimezone = (e: any) => {
    setSelectedTimezone(e);
    localStorage.setItem("selectedTimezone", JSON.stringify(e));
  }

  return (
    <div>
      <div className="top-0 w-full flex flex-wrap">
        <section className="x-auto">
          <nav className=" bg-off-black text-gray-lines w-screen shadow shadow-medium-gray">
            <div className=" flex justify-between px-5 xl:px-12 py-6 flex w-full items-center">
              <div className="flex flex-row items-end">
                <img src={logo_grey} alt="gundi logo" className="h-8 mr-6" />
                <h3 className="text-2xl font-bold text-white font-heading -mb-1 mr-6 pb-0">
                  {process.env.REACT_APP_TEST_VAR}
                </h3>

                <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
                  <li>
                    <NavLink data-testid="nav_connections_btn" to="/">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>{t("Connections")}</span>)}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink data-testid="nav_destinations_btn" to="/destinations">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>{t("Destinations")}</span>)}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink data-testid="nav_sources_btn" to="/sources">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>{t("Sources")}</span>)}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink data-testid="nav_organizations_btn" to="/organizations">
                      {({ isActive }) => (<span className={isActive ? "pb-6 text-white border-2 border-off-black border-b-bright-green" : "hover:text-neutral-gray-400 border-2 border-off-black"}>{t("Organizations")}</span>)}
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="flex flex-row text-neutral-gray-900 items-center">
                {/* tz select */}
                {/* <div className="mx-3 cursor-pointer flex justify-end w-content">
                  <SelectInput
                    options={timezones}
                    data-testid="select_timezone_activity_logs_filter"
                    className={"basic-single text-sm"}
                    value={selectedTimezone} // Use value instead of defaultValue
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    name={"Filter by Organization"}
                    onChange={(e: any) => handleSelectTimezone(e)}
                    timezoneSelect={true}
                  />
                </div> */}
                {/* help menu */}
                <div className="mx-3 cursor-pointer flex justify-end pb-1" onClick={toggleHelpMenu}>
                  <div><HelpIcon helpMenu={helpMenu} /></div>
                  <div className={!helpMenu ? "hidden" : " z-30 absolute bg-light-gray mt-7 shadow rounded"}>
                    <ul className="">
                      <li
                        className="cursor-pointer text-off-black font-semibold hover:bg-white p-2 rounded"
                      >
                        <a href="https://support.earthranger.com/en_US/gundi" target="_blank" rel="noopener noreferrer">{t("Help Center")}</a>
                      </li>
                      <li
                        className="cursor-pointer text-off-black font-semibold hover:bg-white p-2 rounded"
                      >
                        <a href="https://support.earthranger.com/en_US/gundi-getting-help/gundi-contact-us" target="_blank" rel="noopener noreferrer">{t("Contact Support")}</a>
                      </li>
                      <li
                        className="cursor-pointer text-off-black font-semibold hover:bg-white p-2 rounded"
                      >
                        <a href="https://projectgundi.org/Legal-Pages/User-Agreement" target="_blank" rel="noopener noreferrer">{t("Gundi EULA")}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* language menu */}
                <div className="flex justify-end">
                  <SelectInput
                    options={portalLanguages(t)}
                    className={"basic-single text-off-black mr-3 cursor-pointer"}
                    value={{
                      label: currentLanguage,
                      value: i18n.language
                    }}
                    defaultValue={{
                      label: currentLanguage,
                      value: i18n.language
                    }}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    name={"Language"}
                    onChange={(e: any) => handleLanguageChange(e.value)}
                    languageSelect={true}
                  />
                </div>
                {/* profile dropdown */}
                {isLoggedIn ? (
                  <div className="flex justify-end">
                    <div onClick={toggleSubMenu} className="text-white">{keycloak?.authenticated ? (parsedToken?.given_name + " " + parsedToken?.family_name) : ""} {subMenu ? <NavBarSortedUp /> : <NavBarSortedDown />}</div>

                    <div ref={divRef} className={!subMenu ? "hidden" : " z-30 absolute bg-light-gray mt-7 shadow rounded"}>
                      <ul className="">
                        <li
                          data-testid="nav_logout_btn"
                          className="cursor-pointer text-off-black font-semibold hover:bg-white p-2 rounded"
                          onClick={logoutUser}
                        >
                          {t("Log Out")}
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <button
                    data-testid="nav_login_btn"
                    type="button"
                    className="cursor-pointer text-gray-lines font-semibold rounded"
                    onClick={loginUser}
                  >
                    {t("Log In")}
                  </button>
                )}
              </div>
            </div>
          </nav>
        </section>
      </div>
      {showEULAModal &&
        <EulaModal />
      }
    </div>
  );
};

export default Nav;